import { GetArrayElementType } from '../utils/GetArrayElementType'

// built with all api data
const saveRepairCodes = [
  'BAT',
  'CAM_FRONT',
  'LCD',
  'BACK_COVER',
  'CAM_REAR',
  'DIAG',
  'RNL',
  'TCT',
  'DOX',
  'VIB',
  'PCONNECTOR',
  'ECO',
  'HP',
  'HAUT PARLEUR',
  'ANT',
  'BTN_HOME',
  'BTN_POW',
  'SWAP',
  'MIC',
  'BTN_VIB',
  'CON',
  'BTN_VOL',
  'MULTI',
] as const

export type ISaveRepairCode = GetArrayElementType<typeof saveRepairCodes>

export default saveRepairCodes
