import { castSaveRepairCode } from '../utils/castSaveRepairCode'
import { RepairShopQueryVariables } from '../_generated/codegen/graphqlTypes'
import { repairModelPageBuilder } from './RepairModelPage.context'

export type RepairShopPageContext = RepairShopQueryVariables & {
  slug: string
  repairCode: string
  shopsCount: number
}

const generateRepairSlug = (repairCodeStr: string): string => {
  const repairCode = castSaveRepairCode(repairCodeStr)
  switch (repairCode) {
    case 'DOX':
      return 'desoxydation'
    case 'LCD':
      return 'reparation-ecran'
    case 'SWAP':
      return 'echange-standard'
    case 'BAT':
      return 'remplacement-batterie'
    case 'BTN_VOL':
      return 'remplacement-bouton-volume'
    case 'BTN_POW':
      return 'remplacement-bouton-power'
    case 'BTN_HOME':
      return 'remplacement-bouton-home'
    case 'BTN_VIB':
    case 'VIB':
      return 'remplacement-vibreur'
    case 'PCONNECTOR':
    case 'CON':
      return 'remplacement-connecteur-charge'
    case 'CAM_REAR':
      return 'remplacement-camera-arriere'
    case 'CAM_FRONT':
      return 'remplacement-camera-avant'
    case 'RNL':
      return 'mise-a-jour-logiciel'
    case 'BACK_COVER':
      return 'reparation-face-arriere'
    case 'TCT':
      return 'remplacement-tactile'
    case 'HP':
    case 'HAUT PARLEUR':
      return 'haut-parleur'
    case 'DIAG':
      return 'diagnostic'
    case 'ANT':
      return 'remplacement-antenne-reseau'
    case 'ECO':
      return 'remplacement-ecouteur-interne'
    case 'MIC':
      return 'remplacement-micro'
    case 'MULTI':
      return 'multiples-reparations'
    default:
      return repairCodeStr.toLowerCase()
  }
}

export const repairShopPageBuilder = (
  brandName: string,
  slug: string,
  repairCode: string
) => {
  const repairSlug = generateRepairSlug(repairCode)
  return `${repairModelPageBuilder(brandName, slug)}${repairSlug}/`
}
